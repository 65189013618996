<template>
  <div>
    <c-card title="뇌심혈관계 결과 엑셀업로드'" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn 
            v-if="editable" 
            label="LBLAPPLY" 
            icon="save"
            @btnClicked="acceptData" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        </div>
      </template>
    </c-card>
    <c-excel-upload 
      :editable="editable"
      :excelUploadInfo="excelUploadInfo"
    />
  </div>
</template>

<script>
export default {
  name: 'brainCardiovascularExcelUpload',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
        }
      },
    },
  },
  data() {
    return {
      excelUploadInfo: {
        rowKeys: ['heaBrainCardiovascularSurveyId'], // 데이터들의 키 속성값
        taskClassCd: 'BRAIN_CARDIOVASCULAR', // 엑셀업로드 양식 구분 값
        sheetNum: 0,
        columns: [[
          {
            name: 'empNo',
            label: '사번',
            required: true,
            style: 'width:100px',
            align: 'center',
            type: 'emp',
            userId: 'userId',
            userName: 'userName',
            deptCd: 'deptCd',
            deptName: 'deptName',
          },
          {
            name: 'surveyDate',
            label: '검진일자',
            required: true,
            style: 'width:130px',
            align: 'left',
            type: 'date'
          },
          {
            name: 'BCWI000070',
            label: '가족력 나이',
            style: 'width:120px',
            align: 'center',
          },
          {
            name: 'BCWI000006',
            label: '음주유/무',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000064',
            label: '위험인자',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000046',
            label: '발병 정상군',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000047',
            label: '발병 저위험군',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000048',
            label: '발병 중등도위험군',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000049',
            label: '발병 고위험군',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000050',
            label: '질병 신장질환',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000051',
            label: '질병 당뇨',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000052',
            label: '질병 뇌혈관및심혈관질환',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000053',
            label: '질병 말초혈관질환',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000040',
            label: '가족력 부모',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000041',
            label: '가족력 형제,자매',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000042',
            label: '가족력 부모,형제,자매',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000043',
            label: '가족력 뇌졸증',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000044',
            label: '가족력 협심증',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000045',
            label: '가족력 심근경색증',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000036',
            label: '규칙적인운동',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000037',
            label: '운동부족',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000065',
            label: '위험인자 판정이유',
            style: 'width:300px',
            align: 'left',
          },
          {
            name: 'BCWI000067',
            label: '소견및조치사항',
            style: 'width:300px',
            align: 'left',
          },
          {
            name: 'BCWI000054',
            label: '과거및현병력',
            style: 'width:300px',
            align: 'left',
          },
          {
            name: 'BCWI000007',
            label: '흡연유/무',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000055',
            label: '표적 좌심비대',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000056',
            label: '표적 단백뇨',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000057',
            label: '표적 죽상동맥경화증',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000058',
            label: '표적 고혈압성망막증',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000059',
            label: '업무 통상근무자',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000060',
            label: '업무 조건근무자',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000061',
            label: '업무 병가/휴직',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000062',
            label: '업무 작업전환',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000001',
            label: '신장',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000002',
            label: '체중',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000003',
            label: 'bmi',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000008',
            label: '수축기혈압',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000009',
            label: '이완기혈압',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000013',
            label: '총콜레스테롤',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000031',
            label: 'LDL',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000032',
            label: 'HDL',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000069',
            label: '트리글리세라이드',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000011',
            label: '공복혈당',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000033',
            label: '심전도',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000034',
            label: '요소질소',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000035',
            label: '단백뇨',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000014',
            label: '업무 작업전환크레아티닌',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000063',
            label: '최고위험군',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000004',
            label: '허리둘레',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'BCWI000066',
            label: '유해인자사유',
            style: 'width:300px',
            align: 'left',
          },
          {
            name: 'BCWI000068',
            label: '심전도검사(상세내용)',
            style: 'width:300px',
            align: 'left',
          },
          {
            name: 'BCWI000015',
            label: '신사구체여과율(GFR)',
            style: 'width:100px',
            align: 'center',
          },
        ]],
        data: [],
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
    },
    acceptData() {
      if (this.excelUploadInfo.data) {
        let dataCntCheck = true;
        let errorDataCheck = false;
        if (!this.excelUploadInfo.data[0] || this.excelUploadInfo.data[0].length === 0) {
          dataCntCheck = false;
        }
        if (this.$_.findIndex(this.excelUploadInfo.data[0], (item) => { return item.error_message }) > -1) {
          errorDataCheck = true
        }

        if (!dataCntCheck) {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: '업로드할 데이터가 없는 Sheet가 있습니다.\n데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.', // 업로드할 데이터가 없는 Sheet가 있습니다.\n\r데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.
            type: 'warning', // success / info / warning / error
          });
        } else {
          let message = '적용하시겠습니까?\n(※ 기존 데이터를 지운 후 업로드됩니다.)'
          if (errorDataCheck) {
            message = '에러가 있는 데이터가 존재합니다.\n에러 있는 데이터를 제외한 나머지 데이터를 적용하시겠습니까?\n(※ 기존 데이터를 지운 후 업로드됩니다.)'
          }
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: message,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.$emit('closePopup', this.excelUploadInfo.data[0]);
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        }
      }
    }
  }
};
</script>
